<template>
  <div>
    <div v-if="(type === 'experiment' && experiment) || (type === 'template' && templateType === 'experiment' && experiment)" class="md-layout" tabindex="0" @keydown.ctrl.83.prevent="save()">
      <div class="md-layout-item md-size-100">
        <md-card v-if="type === 'experiment'" class="experiment-main-properties">
          <md-card-header>
            <div class="card-icon">
              <md-icon>important_devices</md-icon>
            </div>
            <div class="panel-icon-bar">
              <md-button v-if="!disableForms" title="Load from template" @click.native="loadFromTemplate('experiment')" class="md-simple md-just-icon"><md-icon>restore_page</md-icon></md-button>
              <md-button title="Save as template" @click.native="saveAsTemplate('experiment')" class="md-simple md-just-icon"><md-icon>note_add</md-icon></md-button>
            </div>
            <h4 class="title">{{ experiment.name }}</h4>
          </md-card-header>
          <md-card-content>
            <div class="md-layout">
              <div v-if="experiment.status != 1" class="md-layout-item md-size-100">
                <md-button @click.native="run()" title="Run experiment" class="md-success md-just-icon md-round play-button"><md-icon>play_arrow</md-icon></md-button>
                <md-button @click.native="getReport(1)" title="Get raw data (finished)" class="md-default md-just-icon md-round get-report-button"><md-icon>playlist_add_check</md-icon></md-button>
                <md-button @click.native="getReport(0)" title="Get raw data (incomplete)" class="md-default md-just-icon md-round get-report-button"><md-icon>short_text</md-icon></md-button>
                <md-button v-if="experiment.status==='2' || experiment.status==='3'" @click.native="deleteResults()" title="Remove results" class="md-default md-just-icon md-round get-report-button"><md-icon>cancel_presentation</md-icon></md-button>
                <md-button @click.native="stats()" title="Show statistics" class="md-default md-just-icon md-round get-report-button"><md-icon>insert_chart_outlined</md-icon></md-button>
              </div>
              <div class="md-layout-item md-size-25 md-small-size-100">
                <md-field>
                  <label>Experiment name</label>
                  <md-input
                    v-model="experiment.name"
                    type="text"
                    v-disabled="disableForms"
                    required>
                  </md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-25 md-small-size-100">
                <md-field>
                  <label>Slug</label>
                  <md-input
                    v-model="experiment.slug"
                    type="text"
                    v-disabled="disableForms"
                    required>
                  </md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-25 md-small-size-100">
                <md-field>
                  <label for="movie">Status</label>
                  <md-select v-model="status" @md-selected="newStatus" name="status" id="status">
                    <md-option value="1">Draft</md-option>
                    <md-option value="2">Ready to go</md-option>
                    <md-option value="3">Live</md-option>
                    <md-option value="4">Completed</md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item md-size-25 md-small-size-100">
                <template v-if="!disableForms">
                  <md-button v-if="touched.experiment" @click.native="save()" class="md-success save-button-top"><md-icon>save</md-icon>Save</md-button>
                  <md-button v-if="mode == 'code'" @click.native="toggleMode('visual')" title="Visual mode" class="md-default toggle-mode-button"><md-icon>wallpaper</md-icon></md-button>
                  <md-button v-else @click.native="toggleMode('code')" title="Code mode" class="md-default toggle-mode-button"><md-icon>code</md-icon></md-button>
                </template>
              </div>
              <div class="md-layout-item md-size-100">
                <collapse
                  :collapse="[
                    'CSS'
                  ]"
                  icon="keyboard_arrow_down"
                  name="experiment-scripts"
                  color-collapse="success">
                  <template slot="md-collapse-pane-1">
                    <codemirror v-model="experiment.code.style" :options="cmCssOptions"></codemirror>
                  </template>
                </collapse>
              </div>
            </div>
          </md-card-content>
        </md-card>
        <md-card v-if="type === 'template'" class="experiment-main-properties">
          <md-card-header>
            <div class="card-icon">
              <md-icon>description</md-icon>
            </div>
            <h4 class="title">{{ experiment.name }}</h4>
          </md-card-header>
          <md-card-content>
            <div class="md-layout">
              <div class="md-layout-item md-size-25 md-small-size-100">
                <md-field>
                  <label>Template name</label>
                  <md-input
                    v-model="experiment.name"
                    type="text"
                    required>
                  </md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-25 md-small-size-100">
                <md-chips v-model="experiment.tags" class="md-success" md-placeholder="Enter tags here..."></md-chips>
              </div>
              <div class="md-layout-item md-size-25 md-small-size-100">

              </div>
              <div class="md-layout-item md-size-25 md-small-size-100">
                <md-button @click.native="save()" class="md-success save-button-top"><md-icon>save</md-icon>Save</md-button>
              </div>
              <div class="md-layout-item md-size-100">
                <collapse
                  :collapse="[
                    'CSS'
                  ]"
                  icon="keyboard_arrow_down"
                  name="template-scripts"
                  color-collapse="success">
                  <template slot="md-collapse-pane-1">
                    <codemirror v-model="experiment.code.style" :options="cmCssOptions"></codemirror>
                  </template>
                </collapse>
              </div>
            </div>
          </md-card-content>
        </md-card>
        <div v-if="mode === 'visual'">
          <md-button v-if="!disableForms && type === 'experiment'" @click.native="manageGroupsModal=true" class="md-default md-sm manage-parts-button">Manage groups</md-button>
          <tabs
            :tab-name="tabs.names"
            :tab-icon="tabs.icons"
            :reload="tabs.reload"
            class="experiment-editor"
            nav-pills-icons
            plain
            flex-column
            color-button="primary">
            <template v-for="(section, index) in experiment.code.sections" :slot="'tab-pane-' + (index+1) + ''">
              <div :key="index">
                <md-card>
                  <md-card-header>
                    <div class="card-icon">
                      <md-icon>settings</md-icon>
                    </div>
                    <h4 class="title">Settings</h4>
                    <p class="category">"{{ section.name }}" settings</p>
                    <div class="panel-icon-bar">
                      <md-button v-if="!disableForms && index > 0" title="Move up section" @click.native="moveUpSection(index)" class="md-simple md-just-icon"><md-icon>expand_less</md-icon></md-button>
                      <md-button v-if="!disableForms && index < experiment.code.sections.length-1" title="Move down section" @click.native="moveDownSection(index)" class="md-simple md-just-icon"><md-icon>expand_more</md-icon></md-button>
                      <md-button v-if="!disableForms" title="Load from template" @click.native="loadFromTemplate('section', index)" class="md-simple md-just-icon"><md-icon>restore_page</md-icon></md-button>
                      <md-button title="Save as template" @click.native="saveAsTemplate('section', index)" class="md-simple md-just-icon"><md-icon>note_add</md-icon></md-button>
                      <md-button v-if="!disableForms" title="Delete section" @click.native="dropSection(index)" class="md-simple md-just-icon"><md-icon>delete_outline</md-icon></md-button>
                    </div>
                  </md-card-header>
                  <md-card-content>
                    <div class="md-layout">
                      <div class="md-layout-item md-size-50 md-small-size-100">
                        <!-- <md-switch v-model="experiment.code.sections[index].shuffle" @change="shuffleSection(index)">Shuffle this section with others</md-switch> -->
                        <md-switch v-model="experiment.code.sections[index].shuffleScreens">Shuffle screens in this section</md-switch>
                      </div>
                      <div class="md-layout-item md-size-30 md-small-size-100">
                        <md-field :class="[
                          {'md-valid': !errors.has('experiment.code.sections[index].name') && touched.experiment},
                          {'md-error': errors.has('experiment.code.sections[index].name')}]">
                          <label>Section name</label>
                          <md-input
                            v-model="experiment.code.sections[index].name"
                            data-vv-name="experiment.code.sections[index].name"
                            type="text"
                            v-disabled="disableForms"
                            required
                            @change="build(experiment.code.sections[index].name)"
                            v-validate="newSectionValidations.newSectionName">
                          </md-input>
                          <slide-y-down-transition>
                            <md-icon class="error" v-show="errors.has('experiment.code.sections[index].name') && touched.experiment">close</md-icon>
                          </slide-y-down-transition>
                          <slide-y-down-transition>
                            <md-icon class="success" v-show="!errors.has('experiment.code.sections[index].name') && touched.experiment">done</md-icon>
                          </slide-y-down-transition>
                        </md-field>
                      </div>
                      <div class="md-layout-item md-size-100">
                        <collapse
                          :collapse="[
                            'CSS',
                            'Before Script',
                            'After Script']"
                          icon="keyboard_arrow_down"
                          name="section-scripts"
                          color-collapse="success">
                          <template slot="md-collapse-pane-1">
                            <codemirror v-model="experiment.code.sections[index].style" :options="cmCssOptions"></codemirror>
                          </template>
                          <template slot="md-collapse-pane-2">
                            <codemirror v-model="experiment.code.sections[index].scripts.before" :options="cmJsOptions"></codemirror>
                          </template>
                          <template slot="md-collapse-pane-3">
                            <codemirror v-model="experiment.code.sections[index].scripts.after" :options="cmJsOptions"></codemirror>
                          </template>
                        </collapse>
                      </div>
                    </div>
                  </md-card-content>
                </md-card>
                <template v-for="(screen, scrIndex) in experiment.code.sections[index].screens">
                  <div :key="scrIndex" class="screen-container">
                    <md-card>
                      <md-card-header>
                        <div class="card-icon">
                          <md-icon>{{ screen.icon }}</md-icon>
                        </div>
                        <h4 class="title">{{ screen.name }}</h4>
                        <div class="panel-icon-bar">
                          <md-button v-if="!disableForms && scrIndex > 0" title="Move up screen" @click.native="moveUpScreen(index, scrIndex)" class="md-simple md-just-icon"><md-icon>expand_less</md-icon></md-button>
                          <md-button v-if="!disableForms &&scrIndex < experiment.code.sections[index].screens.length-1" title="Move down screen" @click.native="moveDownScreen(index, scrIndex)" class="md-simple md-just-icon"><md-icon>expand_more</md-icon></md-button>
                          <md-button v-if="!disableForms" title="Load from template" @click.native="loadFromTemplate('screen', index, scrIndex)" class="md-simple md-just-icon"><md-icon>restore_page</md-icon></md-button>
                          <md-button title="Save as template" @click.native="saveAsTemplate('screen', index, scrIndex)" class="md-simple md-just-icon"><md-icon>note_add</md-icon></md-button>
                          <md-button v-if="!disableForms" title="Delete screen" @click.native="dropScreen(index, scrIndex)" class="md-simple md-just-icon"><md-icon>delete_outline</md-icon></md-button>
                        </div>
                      </md-card-header>
                      <md-card-content>
                        <div class="md-layout">
                          <div class="md-layout-item md-size-30 md-small-size-100">
                            <md-field :class="[
                              {'md-valid': !errors.has('experiment.code.sections[index].screens[scrIndex].name') && touched.experiment},
                              {'md-error': errors.has('experiment.code.sections[index].screens[scrIndex].name')}]">
                              <label>Screen name</label>
                              <md-input
                                v-model="experiment.code.sections[index].screens[scrIndex].name"
                                data-vv-name="experiment.code.sections[index].screens[scrIndex].name"
                                type="text"
                                v-disabled="disableForms"
                                required
                                v-validate="screenValidations.screenName">
                              </md-input>
                              <slide-y-down-transition>
                                <md-icon class="error" v-show="errors.has('experiment.code.sections[index].screens[scrIndex].name') && touched.experiment">close</md-icon>
                              </slide-y-down-transition>
                              <slide-y-down-transition>
                                <md-icon class="success" v-show="!errors.has('experiment.code.sections[index].screens[scrIndex].name') && touched.experiment">done</md-icon>
                              </slide-y-down-transition>
                            </md-field>
                          </div>
                          <div class="md-layout-item md-size-70 md-small-size-100">

                          </div>
                          <div class="md-layout-item md-size-100">
                            <br />
                          </div>
                          <div class="md-layout-item md-size-50 md-small-size-100">
                            <md-button v-if="!disableForms" title="Add element" @click="loadFromTemplate('element', index, scrIndex)" class="md-button md-sm md-success md-round">Add element</md-button>
                            <div v-for="(element, elIndex) in experiment.code.sections[index].screens[scrIndex].elements" :key="elIndex">
                              <div class="md-group">
                                <md-button v-if="!disableForms && elIndex == 0" title="Move up element" disabled class="md-button md-just-icon"><md-icon>expand_less</md-icon></md-button>
                                <md-button v-if="!disableForms && elIndex > 0" title="Move up element" @click.native="moveUpElement(index, scrIndex, elIndex)" class="md-button md-just-icon"><md-icon>expand_less</md-icon></md-button>
                                <md-button v-if="!disableForms && elIndex < experiment.code.sections[index].screens[scrIndex].elements.length-1" title="Move down element" @click.native="moveDownElement(index, scrIndex, elIndex)" class="md-button md-just-icon"><md-icon>expand_more</md-icon></md-button>
                                <md-button v-if="!disableForms && elIndex == experiment.code.sections[index].screens[scrIndex].elements.length-1" title="Move down element" disabled class="md-button md-just-icon"><md-icon>expand_more</md-icon></md-button>
                                <md-button v-if="!disableForms" title="Delete element" @click.native="dropElement(index, scrIndex, elIndex)" class="md-button md-just-icon"><md-icon>delete_outline</md-icon></md-button>
                                <md-button @click="editElement(index, scrIndex, elIndex)" class="md-button md-default">{{ element.name }}</md-button>
                              </div>
                            </div>
                          </div>
                          <div class="md-layout-item md-size-50 md-small-size-100">
                            <div class="screen-preview-buttons">
                              <md-button title="Reload preview" @click.native="previewScreen(index, scrIndex)" class="md-simple md-just-icon"><md-icon>autorenew</md-icon></md-button>
                              <md-button v-if="previewOrientation==='p'" title="Landscape" @click.native="setPreviewOrientation('l')" class="md-simple md-just-icon"><md-icon>stay_current_landscape</md-icon></md-button>
                              <md-button v-if="previewOrientation==='l'" title="Portrait" @click.native="setPreviewOrientation('p')" class="md-simple md-just-icon"><md-icon>stay_current_portrait</md-icon></md-button>
                            </div>
                            <div class="screen-content" :class="[previewOrientation === 'l' ? 'landscape' : 'portrait']">
                              <div>
                                <iframe @load="previewScreen(index, scrIndex)" :id="'preview-screen-' + index + '-' + scrIndex"></iframe>
                              </div>
                            </div>
                          </div>
                          <div class="md-layout-item md-size-100">
                            <collapse
                              :collapse="[
                                'Additional HTML',
                                'CSS',
                                'Before Script',
                                'After Script',
                                'Loop']"
                              icon="keyboard_arrow_down"
                              name="section-scripts"
                              color-collapse="success">
                              <template slot="md-collapse-pane-1">
                                <codemirror v-model="experiment.code.sections[index].screens[scrIndex].html" :options="cmHtmlOptions"></codemirror>
                              </template>
                              <template slot="md-collapse-pane-2">
                                <codemirror v-model="experiment.code.sections[index].screens[scrIndex].style" :options="cmCssOptions"></codemirror>
                              </template>
                              <template slot="md-collapse-pane-3">
                                <codemirror v-model="experiment.code.sections[index].screens[scrIndex].scripts.before" :options="cmJsOptions"></codemirror>
                              </template>
                              <template slot="md-collapse-pane-4">
                                <codemirror v-model="experiment.code.sections[index].screens[scrIndex].scripts.after" :options="cmJsOptions"></codemirror>
                              </template>
                              <template slot="md-collapse-pane-5">
                                <codemirror v-model="experiment.code.sections[index].screens[scrIndex].loop" :options="cmJsOptions"></codemirror>
                              </template>
                            </collapse>
                          </div>
                        </div>
                      </md-card-content>
                    </md-card>
                  </div>
                </template>
                <div v-if="!disableForms" class="md-layout-item md-size-40 md-small-size-100 mx-auto">
                  <md-button @click.native="addScreen(index)" class="md-button md-success md-round md-block"><md-icon>add_to_queue</md-icon> Add screen</md-button>
                </div>
              </div>
            </template>
            <template :slot="'tab-pane-' + (experiment.code.sections.length + 1) + ''">
              <md-card>
                <md-card-header>
                  <div class="card-icon">
                    <md-icon>add</md-icon>
                  </div>
                  <h4 class="title">Add section</h4>
                  <p class="category"></p>
                </md-card-header>
                <md-card-content>
                  <div class="md-layout">
                    <div class="md-layout-item md-size-40 md-small-size-100">
                      <md-field :class="[
                        {'md-valid': !errors.has('newSectionName') && touched.newSectionName},
                        {'md-error': errors.has('newSectionName')}]">
                        <label>New section name</label>
                        <md-input
                          v-model="newSectionName"
                          data-vv-name="newSectionName"
                          type="newSectionName"
                          required
                          v-disabled="disableForms"
                          v-validate="newSectionValidations.newSectionName">
                        </md-input>
                        <slide-y-down-transition>
                          <md-icon class="error" v-show="errors.has('newSectionName') && touched.newSectionName">close</md-icon>
                        </slide-y-down-transition>
                        <slide-y-down-transition>
                          <md-icon class="success" v-show="!errors.has('newSectionName') && touched.newSectionName">done</md-icon>
                        </slide-y-down-transition>
                      </md-field>
                    </div>
                  </div>
                </md-card-content>
                <md-card-actions md-alignment="right">
                  <md-button native-type="submit" @click.native.prevent="validate" class="md-success" v-disabled="disableForms"><md-icon>add</md-icon>Add</md-button>
                </md-card-actions>
              </md-card>
            </template>
          </tabs>
        </div>
        <div v-else class="code-mode-container">
          <codemirror v-model="experiment.codeString" :options="cmJSONOptions"></codemirror>
        </div>
        <div class="md-layout-item md-size-100 save-button-bottom">
          <md-button v-if="false && touched.any" @click.native="save()" class="md-success"><md-icon>save</md-icon>Save</md-button>
        </div>
      </div>
    </div>
    <div v-else-if="type === 'template' && templateType === 'section' && template">
      <md-card>
        <md-card-header>
          <div class="card-icon">
            <md-icon>crop_free</md-icon>
          </div>
          <h4 class="title">{{ template.name }}</h4>
        </md-card-header>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-33 md-small-size-100">
              <md-field>
                <label>Template name</label>
                <md-input
                  v-model="template.name"
                  data-vv-name="template.name"
                  type="text"
                  required>
                </md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <md-field>
                <label>Section name</label>
                <md-input
                  v-model="template.code.name"
                  data-vv-name="template.code.name"
                  type="text"
                  required>
                </md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <md-button @click.native="save()" class="md-success save-button-top"><md-icon>save</md-icon>Save</md-button>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <md-chips v-model="template.tags" class="md-success" md-placeholder="Enter tags here..."></md-chips>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <md-switch v-model="template.code.shuffleScreens">Shuffle screens in this section</md-switch>
            </div>
            <div class="md-layout-item md-size-100">
              <collapse
                :collapse="[
                  'Before Script',
                  'After Script']"
                icon="keyboard_arrow_down"
                name="section-scripts"
                color-collapse="success">
                <template slot="md-collapse-pane-1">
                  <codemirror v-model="template.code.scripts.before" :options="cmJsOptions"></codemirror>
                </template>
                <template slot="md-collapse-pane-2">
                  <codemirror v-model="template.code.scripts.after" :options="cmJsOptions"></codemirror>
                </template>
              </collapse>
            </div>
          </div>
        </md-card-content>
      </md-card>
      <template v-for="(screen, scrIndex) in template.code.screens">
        <div :key="scrIndex" class="screen-container">
          <md-card>
            <md-card-header>
              <div class="card-icon">
                <md-icon>{{ screen.icon }}</md-icon>
              </div>
              <h4 class="title">{{ screen.name }}</h4>
              <div class="panel-icon-bar">
                <md-button v-if="scrIndex > 0" title="Move up screen" @click.native="moveUpScreen('template', scrIndex)" class="md-simple md-just-icon"><md-icon>expand_less</md-icon></md-button>
                <md-button v-if="scrIndex < template.code.screens.length-1" title="Move down screen" @click.native="moveDownScreen('template', scrIndex)" class="md-simple md-just-icon"><md-icon>expand_more</md-icon></md-button>
                <md-button title="Delete screen" @click.native="dropScreen('template', scrIndex)" class="md-simple md-just-icon"><md-icon>delete_outline</md-icon></md-button>
              </div>
            </md-card-header>
            <md-card-content>
              <div class="md-layout">
                <div class="md-layout-item md-size-30 md-small-size-100">
                  <md-field>
                    <label>Screen name</label>
                    <md-input
                      v-model="template.code.screens[scrIndex].name"
                      data-vv-name="template.code.screens[scrIndex].name"
                      type="text"
                      required>
                    </md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-70 md-small-size-100">

                </div>
                <div class="md-layout-item md-size-100">
                  <br />
                </div>
                <div class="md-layout-item md-size-50 md-small-size-100">
                  <md-button title="Add element" @click="loadFromTemplate('element', 0, scrIndex)" class="md-button md-sm md-success md-round">Add element</md-button>
                  <div v-for="(element, elIndex) in screen.elements" :key="elIndex">
                    <div class="md-group">
                      <md-button v-if="elIndex == 0" title="Move up element" disabled class="md-button md-just-icon"><md-icon>expand_less</md-icon></md-button>
                      <md-button v-if="elIndex > 0" title="Move up element" @click.native="moveUpElement('template', scrIndex, elIndex)" class="md-button md-just-icon"><md-icon>expand_less</md-icon></md-button>
                      <md-button v-if="elIndex < screen.elements.length-1" title="Move down element" @click.native="moveDownElement('template', scrIndex, elIndex)" class="md-button md-just-icon"><md-icon>expand_more</md-icon></md-button>
                      <md-button v-if="elIndex == screen.elements.length-1" title="Move down element" disabled class="md-button md-just-icon"><md-icon>expand_more</md-icon></md-button>
                      <md-button title="Delete element" @click.native="dropElement('template', scrIndex, elIndex)" class="md-button md-just-icon"><md-icon>delete_outline</md-icon></md-button>
                      <md-button @click="editElement('template', scrIndex, elIndex)" class="md-button md-default">{{ element.name }}</md-button>
                    </div>
                  </div>
                </div>
                <div class="md-layout-item md-size-50 md-small-size-100">
                  <div class="screen-preview-buttons">
                    <md-button title="Reload preview" @click.native="previewScreen('template', scrIndex)" class="md-simple md-just-icon"><md-icon>autorenew</md-icon></md-button>
                    <md-button v-if="previewOrientation==='p'" title="Landscape" @click.native="setPreviewOrientation('l')" class="md-simple md-just-icon"><md-icon>stay_current_landscape</md-icon></md-button>
                    <md-button v-if="previewOrientation==='l'" title="Portrait" @click.native="setPreviewOrientation('p')" class="md-simple md-just-icon"><md-icon>stay_current_portrait</md-icon></md-button>
                  </div>
                  <div class="screen-content" :class="[previewOrientation === 'l' ? 'landscape' : 'portrait']">
                    <div>
                      <iframe @load="previewScreen('template', scrIndex)" :id="'preview-screen-template-' + scrIndex"></iframe>
                    </div>
                  </div>
                </div>
                <div class="md-layout-item md-size-100">
                  <collapse
                    :collapse="[
                      'Additional HTML',
                      'CSS',
                      'Before Script',
                      'After Script',
                      'Loop']"
                    icon="keyboard_arrow_down"
                    name="section-scripts"
                    color-collapse="success"
                    class="cm-container">
                    <template slot="md-collapse-pane-1">
                      <codemirror v-model="template.code.screens[scrIndex].html" :options="cmHtmlOptions"></codemirror>
                    </template>
                    <template slot="md-collapse-pane-2">
                      <codemirror v-model="template.code.screens[scrIndex].style" :options="cmCssOptions"></codemirror>
                    </template>
                    <template slot="md-collapse-pane-3">
                      <codemirror v-model="template.code.screens[scrIndex].scripts.before" :options="cmJsOptions"></codemirror>
                    </template>
                    <template slot="md-collapse-pane-4">
                      <codemirror v-model="template.code.screens[scrIndex].scripts.after" :options="cmJsOptions"></codemirror>
                    </template>
                    <template slot="md-collapse-pane-5">
                      <codemirror v-model="template.code.screens[scrIndex].loop" :options="cmJsOptions"></codemirror>
                    </template>
                  </collapse>
                </div>
              </div>
            </md-card-content>
          </md-card>
        </div>
      </template>
      <div class="md-layout-item md-size-40 md-small-size-100 mx-auto">
        <md-button @click.native="addScreen('template')" class="md-button md-success md-round md-block"><md-icon>add_to_queue</md-icon> Add screen</md-button>
      </div>
    </div>
    <div v-else-if="type === 'template' && templateType === 'screen' && template">
      <md-card>
        <md-card-header>
          <div class="card-icon">
            <md-icon>tv</md-icon>
          </div>
          <h4 class="title">{{ template.name }}</h4>
        </md-card-header>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-33 md-small-size-100">
              <md-field>
                <label>Template name</label>
                <md-input
                  v-model="template.name"
                  data-vv-name="template.name"
                  type="text"
                  required>
                </md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">

            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <md-button @click.native="save()" class="md-success save-button-top"><md-icon>save</md-icon>Save</md-button>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <md-chips v-model="template.tags" class="md-success" md-placeholder="Enter tags here..."></md-chips>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-50 md-small-size-100">
              <md-button title="Add element" @click="loadFromTemplate('element', 0, 0)" class="md-button md-sm md-success md-round">Add element</md-button>
              <div v-for="(element, elIndex) in template.code.elements" :key="elIndex">
                <div class="md-group">
                  <md-button v-if="elIndex == 0" title="Move up element" disabled class="md-button md-just-icon"><md-icon>expand_less</md-icon></md-button>
                  <md-button v-if="elIndex > 0" title="Move up element" @click.native="moveUpElement('template', 0, elIndex)" class="md-button md-just-icon"><md-icon>expand_less</md-icon></md-button>
                  <md-button v-if="elIndex < template.code.elements.length-1" title="Move down element" @click.native="moveDownElement('template', 0, elIndex)" class="md-button md-just-icon"><md-icon>expand_more</md-icon></md-button>
                  <md-button v-if="elIndex == template.code.elements.length-1" title="Move down element" disabled class="md-button md-just-icon"><md-icon>expand_more</md-icon></md-button>
                  <md-button title="Delete element" @click.native="dropElement('template', 0, elIndex)" class="md-button md-just-icon"><md-icon>delete_outline</md-icon></md-button>
                  <md-button @click="editElement('template', 0, elIndex)" class="md-button md-default">{{ element.name }}</md-button>
                </div>
              </div>
            </div>
            <div class="md-layout-item md-size-50 md-small-size-100">
              <div class="screen-preview-buttons">
                <md-button title="Reload preview" @click.native="previewScreen('template', 0, 0)" class="md-simple md-just-icon"><md-icon>autorenew</md-icon></md-button>
                <md-button v-if="previewOrientation==='p'" title="Landscape" @click.native="setPreviewOrientation('l')" class="md-simple md-just-icon"><md-icon>stay_current_landscape</md-icon></md-button>
                <md-button v-if="previewOrientation==='l'" title="Portrait" @click.native="setPreviewOrientation('p')" class="md-simple md-just-icon"><md-icon>stay_current_portrait</md-icon></md-button>
              </div>
              <div class="screen-content" :class="[previewOrientation === 'l' ? 'landscape' : 'portrait']">
                <div>
                  <iframe @load="previewScreen('template', 0, 0)" :id="'preview-screen-template-screen'"></iframe>
                </div>
              </div>
            </div>
            <div class="md-layout-item md-size-100">
              <collapse
                :collapse="[
                  'Additional HTML',
                  'CSS',
                  'Before Script',
                  'After Script',
                  'Loop']"
                icon="keyboard_arrow_down"
                name="section-scripts"
                color-collapse="success">
                <template slot="md-collapse-pane-1">
                  <codemirror v-model="template.code.html" :options="cmHtmlOptions"></codemirror>
                </template>
                <template slot="md-collapse-pane-2">
                  <codemirror v-model="template.code.style" :options="cmCssOptions"></codemirror>
                </template>
                <template slot="md-collapse-pane-3">
                  <codemirror v-model="template.code.scripts.before" :options="cmJsOptions"></codemirror>
                </template>
                <template slot="md-collapse-pane-4">
                  <codemirror v-model="template.code.scripts.after" :options="cmJsOptions"></codemirror>
                </template>
                <template slot="md-collapse-pane-5">
                  <codemirror v-model="template.code.loop" :options="cmJsOptions"></codemirror>
                </template>
              </collapse>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
    <div v-else-if="type === 'template' && templateType === 'element' && template">
      <md-card>
        <md-card-header>
          <div class="card-icon">
            <md-icon>star</md-icon>
          </div>
          <h4 class="title">{{ template.name }}</h4>
        </md-card-header>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-33 md-small-size-100">
              <md-field>
                <label>Template name</label>
                <md-input
                  v-model="template.name"
                  data-vv-name="template.name"
                  type="text"
                  required>
                </md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">

            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <md-button @click.native="save()" class="md-success save-button-top"><md-icon>save</md-icon>Save</md-button>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <md-chips v-model="template.tags" class="md-success" md-placeholder="Enter tags here..."></md-chips>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-50 md-small-size-100 template-element-html-container">
              <h4>HTML</h4>
              <codemirror v-model="template.code.template" :options="cmHtmlOptions" @input="elementTemplateDraw"></codemirror>
            </div>
            <div class="md-layout-item md-size-50 md-small-size-100 template-element-css-container">
              <h4>CSS</h4>
              <codemirror v-model="template.code.style" :options="cmCssOptions" @input="elementTemplateDraw"></codemirror>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-50 md-small-size-100">
              <div class="screen-preview-buttons">
                <md-button title="Reload preview" @click.native="previewScreen('template', 0, 0)" class="md-simple md-just-icon"><md-icon>autorenew</md-icon></md-button>
                <md-button v-if="previewOrientation==='p'" title="Landscape" @click.native="setPreviewOrientation('l')" class="md-simple md-just-icon"><md-icon>stay_current_landscape</md-icon></md-button>
                <md-button v-if="previewOrientation==='l'" title="Portrait" @click.native="setPreviewOrientation('p')" class="md-simple md-just-icon"><md-icon>stay_current_portrait</md-icon></md-button>
              </div>
              <div class="screen-content" :class="[previewOrientation === 'l' ? 'landscape' : 'portrait']">
                <div>
                  <iframe @load="previewScreen('template', 0, 0)" :id="'preview-screen-template-element'"></iframe>
                </div>
              </div>
            </div>
            <div class="md-layout-item md-size-50 md-small-size-100">
              <div class="template-element-options">
                <h4>Options defaults</h4>
                <template v-for="(option, opIndex) in template.code.options">
                  <md-field :key="opIndex">
                    <label>{{ opIndex }}</label>
                    <md-input
                      v-model="template.code.options[opIndex]"
                      data-vv-name="template.code.options[opIndex]"
                      @keyup="elementTemplateDraw"
                      @change="elementTemplateDraw"
                      type="text">
                    </md-input>
                  </md-field>
                </template>
              </div>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
    <modal class="edit-element-modal" v-if="editElementModal" @close="editElementModalHide">
      <template slot="header">
        <h4 class="modal-title">Edit {{ editingElement.content.name }}</h4>
        <md-button class="md-simple md-just-icon md-round modal-default-button" @click="editElementModalHide">
          <md-icon>clear</md-icon>
        </md-button>
      </template>
      <template slot="body">
        <p>{{ editingElement.content.hash }} <md-button v-clipboard="editingElement.content.hash" class="md-danger md-simple md-xs copy-button">Copy</md-button></p>
        <div class="perfect-scrollbar-on edit-element-options">
          <template v-for="(option, opIndex) in editingElement.content.options">
            <md-field :key="opIndex">
              <label>{{ opIndex }}</label>
              <md-input
                v-model="editingElement.content.options[opIndex]"
                data-vv-name="editingElement.content.options[opIndex]"
                v-disabled="disableForms"
                type="text">
              </md-input>
            </md-field>
          </template>
        </div>
      </template>
      <template slot="footer">
        <md-button class="md-simple" @click="editElementModalHide">Cancel</md-button>
        <md-button class="md-success md-simple" @click="editElementModalSave">OK</md-button>
      </template>
    </modal>
    <modal v-if="saveAsTemplateModal" @close="saveAsTemplateModalHide">
      <template slot="header">
        <h4 class="modal-title">Save as {{ savingTemplate.type }} template</h4>
        <md-button class="md-simple md-just-icon md-round modal-default-button" @click="saveAsTemplateModalHide">
          <md-icon>clear</md-icon>
        </md-button>
      </template>
      <template slot="body">
        <md-field>
          <label>Name</label>
          <md-input
              v-model="savingTemplate.name"
              data-vv-name="savingTemplate.name"
              type="text"
              name="savingTemplate.name">
          </md-input>
        </md-field>
        <md-chips v-model="savingTemplate.tags" class="md-success" md-placeholder="Enter tags here..."></md-chips>
      </template>
      <template slot="footer">
        <md-button class="md-simple" @click="saveAsTemplateModalHide">Cancel</md-button>
        <md-button class="md-success md-simple" @click="saveAsTemplateModalSave">OK</md-button>
      </template>
    </modal>
    <modal v-if="loadFromTemplateModal" @close="loadFromTemplateModalHide">
      <template slot="header">
        <h4 class="modal-title">Load from {{ loadingTemplate.type }} template</h4>
        <md-button class="md-simple md-just-icon md-round modal-default-button" @click="loadFromTemplateModalHide">
          <md-icon>clear</md-icon>
        </md-button>
      </template>
      <template slot="body">
        <select-template :templateType="loadingTemplate.type" @select="loadTemplate"></select-template>
      </template>
      <template slot="footer">
        <md-button class="md-simple" @click="loadFromTemplateModalHide">Cancel</md-button>
      </template>
    </modal>
    <modal class="manage-groups-modal" v-if="manageGroupsModal" @close="manageGroupsModalHide">
      <template slot="header">
        <h4 class="modal-title">Manage Groups</h4>
        <md-button class="md-simple md-just-icon md-round modal-default-button" @click="manageGroupsModalHide">
          <md-icon>clear</md-icon>
        </md-button>
      </template>
      <template slot="body">
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-button @click.native="addGroup()" class="md-success md-sm md-round add-group-button">Add Group</md-button>
          </div>
          <div class="md-layout-item md-size-50">
            <md-switch
              class="shuffle-group-switch"
              v-model="experiment.code.groupsShuffle"
              @click="$forceUpdate()"
              >Shuffle groups
            </md-switch>
          </div>
        </div>
        <div class="perfect-scrollbar-on groups-items">
          <template v-for="(group, groupIndex) in experiment.code.groups">
            <div :key="groupIndex" class="group-item">
              <div class="md-layout">
                <div class="md-layout-item md-size-80 md-small-size-100">
                  <h6>Group {{ (groupIndex + 1) }}</h6>
                  <md-switch
                    v-model="experiment.code.groups[groupIndex].shuffleSections"
                    @click="$forceUpdate()"
                    >Shuffle sections in this group
                  </md-switch>
                </div>
                <div class="md-layout-item md-size-20 md-small-size-100">
                  <md-button
                    v-if="!disableForms"
                    title="Delete group"
                    @click.native="dropGroup(groupIndex)"
                    class="md-simple md-just-icon pull-right drop-group-button"
                    ><md-icon>delete_outline</md-icon>
                  </md-button>
                </div>
                <div class="md-layout-item md-size-100">
                  <md-checkbox
                    v-for="(section, secIndex) in experiment.code.sections"
                    v-model="experiment.code.groups[groupIndex].sections[secIndex].selected"
                    :key="groupIndex + '-' + secIndex"
                    @change="changeGroup($event, secIndex, groupIndex)"
                    :disabled="experiment.code.groups[groupIndex].sections[secIndex].disabled"
                    >{{ section.name }}
                  </md-checkbox>
                </div>
              </div>
            </div>
          </template>
        </div>
      </template>
      <template slot="footer">
        <md-button class="md-success md-simple" @click="manageGroupsModalHide">OK</md-button>
      </template>
    </modal>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'

function hasElement (className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar (className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import { Tabs, Collapse, Modal, IFrame } from '@/components'
import { SlideYDownTransition } from 'vue2-transitions'
import SelectTemplate from '../Templates/SelectTemplate.vue'
import swal from 'sweetalert2'
import uniqid from 'uniqid'
var beautify_js = require('js-beautify').js_beautify
import { codemirror } from 'vue-codemirror'
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/mode/css/css.js'
import 'codemirror/mode/htmlmixed/htmlmixed.js'
import 'codemirror/addon/display/autorefresh.js'
// require active-line.js
import'codemirror/addon/selection/active-line.js'
// styleSelectedText
import'codemirror/addon/selection/mark-selection.js'
import'codemirror/addon/search/searchcursor.js'
// hint
import'codemirror/addon/hint/show-hint.js'
import'codemirror/addon/hint/show-hint.css'
import'codemirror/addon/hint/javascript-hint.js'
import'codemirror/addon/selection/active-line.js'
// highlightSelectionMatches
import'codemirror/addon/scroll/annotatescrollbar.js'
import'codemirror/addon/search/matchesonscrollbar.js'
import'codemirror/addon/search/searchcursor.js'
import'codemirror/addon/search/match-highlighter.js'
// keyMap
import'codemirror/mode/clike/clike.js'
import'codemirror/addon/edit/matchbrackets.js'
import'codemirror/addon/comment/comment.js'
import'codemirror/addon/dialog/dialog.js'
import'codemirror/addon/dialog/dialog.css'
import'codemirror/addon/search/searchcursor.js'
import'codemirror/addon/search/search.js'
import'codemirror/keymap/sublime.js'
// foldGutter
import'codemirror/addon/fold/foldgutter.css'
import'codemirror/addon/fold/brace-fold.js'
import'codemirror/addon/fold/comment-fold.js'
import'codemirror/addon/fold/foldcode.js'
import'codemirror/addon/fold/foldgutter.js'
import'codemirror/addon/fold/indent-fold.js'
import'codemirror/addon/fold/markdown-fold.js'
import'codemirror/addon/fold/xml-fold.js'

export default {
  name: 'editor',
  components: {
    Tabs,
    Collapse,
    Modal,
    IFrame,
    SelectTemplate,
    SlideYDownTransition,
    codemirror
  },
  props: {
    initialData: {
      type: Object
    },
    type: {
      type: String
    },
    templateType: {
      type: String
    }
  },
  data () {
    return {
      mode: 'visual',
      experiment: {
        code: {
          sections: []
        }
      },
      status: null,
      template: null,
      tabs: {
        names: [],
        icons: [],
        reload: {
          rand: Math.ceil(Math.random()*10)
        }
      },
      disableForms: false,
      editElementModal: false,
      saveAsTemplateModal: false,
      loadFromTemplateModal: false,
      manageGroupsModal: false,
      editingElement: null,
      savingTemplate: null,
      loadingTemplate: null,
      newSectionName: '',
      previewOrientation: 'l',
      touched: {
        experiment: false,
        newSectionName: false
      },
      newSectionValidations: {
        newSectionName: {
          required: true,
        }
      },
      screenValidations: {
        screenName: {
          required: true,
        },
        screenHash: {
          required: true,
        }
      },
      cmJsOptions: {
        tabSize: 2,
        mode: 'text/javascript',
        theme: 'monokai',
        autoRefresh: true,
        lineNumbers: true,
        line: true,
        foldGutter: true,
        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
        hintOptions:{
          completeSingle: false
        },
        keyMap: "sublime",
        matchBrackets: true,
        showCursorWhenSelecting: true,
        extraKeys: { "Alt": "autocomplete" },
        readOnly: false
      },
      cmJSONOptions: {
        tabSize: 2,
        mode: 'text/javascript',
        theme: 'monokai',
        autoRefresh: true,
        lineNumbers: true,
        line: true,
        foldGutter: true,
        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
        hintOptions:{
          completeSingle: false
        },
        keyMap: "sublime",
        matchBrackets: true,
        showCursorWhenSelecting: true,
        extraKeys: { "Alt": "autocomplete" },
        readOnly: false
      },
      cmCssOptions: {
        tabSize: 2,
        mode: 'text/css',
        theme: 'monokai',
        autoRefresh: true,
        lineNumbers: true,
        line: true,
        foldGutter: true,
        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
        hintOptions:{
          completeSingle: false
        },
        keyMap: "sublime",
        matchBrackets: true,
        showCursorWhenSelecting: true,
        extraKeys: { "Alt": "autocomplete" },
        readOnly: false
      },
      cmHtmlOptions: {
        tabSize: 2,
        mode: 'text/html',
        theme: 'monokai',
        autoRefresh: true,
        lineNumbers: true,
        line: true,
        foldGutter: true,
        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
        hintOptions:{
          completeSingle: false
        },
        keyMap: "sublime",
        matchBrackets: true,
        showCursorWhenSelecting: true,
        extraKeys: { "Alt": "autocomplete" },
        readOnly: false
      }
    }
  },
  created() {
    this.touched.any = false
    if(localStorage.getItem('previewOrientation')) this.previewOrientation = localStorage.getItem('previewOrientation')
  },
  mounted() {
    let docClasses = document.body.classList;
    let isWindows = navigator.platform.startsWith('Win');
    if (isWindows) {
      // if we are on windows OS we activate the perfectScrollbar function
      initScrollbar('groups-items')
      initScrollbar('edit-element-options')

      docClasses.add('perfect-scrollbar-on')
    } else {
      docClasses.add('perfect-scrollbar-off')
    }
    setTimeout(() => {
      this.cmJsOptions.styleSelectedText = true
      this.cmJsOptions.styleActiveLine = true
      this.cmCssOptions.styleSelectedText = true
      this.cmCssOptions.styleActiveLine = true
    }, 1800)
  },
  methods: {
    statuses () {
      return [
        {
          id: 1,
          name: 'Draft'
        },
        {
          id: 2,
          name: 'In progress'
        }
      ]
    },
    reset () {
      this.tabs.names = []
      this.tabs.icons = []
    },
    build (active) {
      this.reset()
      this.experiment.code.sections.forEach(section => {
        this.tabs.names.push(section.name)
        this.tabs.icons.push(section.icon)
      })
      this.tabs.names.push('Add Section')
      this.tabs.icons.push('add')
      this.tabs.reload = {
        rand: Math.ceil(Math.random()*10),
        active: active
      }
    },
    save () {
      if (this.type === 'experiment') {
        if (this.mode === 'visual') this.experiment.codeString = beautify_js(JSON.stringify(this.experiment.code), { indent_size: 2 })
        else if (this.mode === 'code') this.experiment.code = JSON.parse(this.experiment.codeString)
        this.$emit('save', this.experiment)
      } else if (this.type === 'template' && this.templateType === 'experiment') {
        this.$emit('save', this.experiment)
      } else if (this.type === 'template' && this.templateType !== 'experiment') {
        if (this.templateType === 'element') {

        }
        this.$emit('save', this.template)
      }
    },
    run () {
      let url = process.env.VUE_APP_SURVEY_URL + this.experiment.slug
      window.open(url, '_blank');
    },
    getReport (finished) {
      var self = this
      var xhr = new XMLHttpRequest()
      xhr.open('GET', this.$root.apiUrl + 'v1/results/' + this.$route.params.id + '/' + this.experiment.status + '/' + finished + '/csv')
      xhr.setRequestHeader('Authorization', localStorage.getItem('token'))
      xhr.onload = () => {
        if (xhr.status === 401) self.$router.push({name: 'Login'})
        var response = JSON.parse(xhr.responseText)
        if (xhr.status === 200 && response.response && response.response.xls) {
          window.open(this.$root.apiUrl + 'files/' + response.response.xls, '_blank');
        } else if (xhr.status === 404) {
          self.$notify({
            message: 'There is no data available.',
            icon: 'notification_important',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'warning'
          })
        }
      }
      xhr.send()
    },
    deleteResults() {
      swal({
        title: 'Are you sure to remove all results?',
        text: '',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove!',
        cancelButtonText: 'No',
        confirmButtonClass: 'md-button md-success',
        cancelButtonClass: 'md-button md-danger',
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          var self = this
          var xhr = new XMLHttpRequest()
          xhr.open('DELETE', this.$root.apiUrl + 'v1/results/' + this.$route.params.id + '/' + this.experiment.status)
          xhr.setRequestHeader('Authorization', localStorage.getItem('token'))
          xhr.onload = () => {
            if (xhr.status === 401) self.$router.push({name: 'Login'})
            if (xhr.status === 201) {
              self.$notify({
                message: 'The results have been removed.',
                icon: 'notification_important',
                horizontalAlign: 'right',
                verticalAlign: 'top',
                type: 'success'
              })
            } else {
              self.$notify({
                message: 'Error',
                icon: 'notification_important',
                horizontalAlign: 'right',
                verticalAlign: 'top',
                type: 'warning'
              })
            }
          }
          xhr.send()
        }
      })
    },
    stats() {
      let routeData = this.$router.resolve({ name: 'Stats', params: { id: this.$route.params.id, status: this.experiment.status } });
      window.open(routeData.href, '_blank');
    },
    toggleMode (mode) {
      this.save()
      this.mode = mode
      if(mode === 'visual') this.build(this.tabs.names[0])
    },
    newStatus (status) {
      if (status == 3 || status == 4) {
        this.disableForms = true
        this.cmJsOptions.readOnly = true
        this.cmJSONOptions.readOnly = true
        this.cmCssOptions.readOnly = true
        this.cmHtmlOptions.readOnly = true
      } else {
        this.disableForms = false
        this.cmJsOptions.readOnly = false
        this.cmJSONOptions.readOnly = false
        this.cmCssOptions.readOnly = false
        this.cmHtmlOptions.readOnly = false
      }
    },
    dropSection (index) {
      swal({
        title: 'Are you sure to delete section:<br />"' + this.experiment.code.sections[index].name +'"?',
        text: '',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete!',
        cancelButtonText: 'No',
        confirmButtonClass: 'md-button md-success',
        cancelButtonClass: 'md-button md-danger',
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.experiment.code.sections.splice(index, 1)
          this.resetGroups()
          this.build(this.tabs.names[0])
        }
      })
    },
    resetGroups() {
      if(this.experiment.code.groups && this.experiment.code.groups.length > 0) {
        swal({
          title: 'Note',
          text: 'Groups have been reset.',
          type: 'warning',
          showCancelButton: false,
          confirmButtonText: 'OK',
          confirmButtonClass: 'md-button md-success',
          buttonsStyling: false
        })
      }
      this.experiment.code.groups = []
    },
    moveUpSection(index) {
      this.experiment.code.sections = this.arrayMove(this.experiment.code.sections, index, index - 1)
      this.resetGroups()
      this.build(this.tabs.names[index])
    },
    moveDownSection(index) {
      this.experiment.code.sections = this.arrayMove(this.experiment.code.sections, index, index + 1)
      this.resetGroups()
      this.build(this.tabs.names[index])
    },
    shuffleSection(index) {
      if (this.experiment.code.sections[index].shuffle) this.experiment.code.sections[index].icon = 'shuffle'
      else this.experiment.code.sections[index].icon = 'crop_free'
      this.build(this.tabs.names[index])
    },
    addScreen(index) {
      let hash = uniqid.time()
      let screen = {
        name: 'SCREEN_' + hash,
        hash: hash,
        icon: 'tv',
        style: '',
        scripts: {
          before: '',
          after: ''
        }
      }
      if (index === 'template') {
        if (!this.template.code.screens) this.template.code.screens = []
        this.template.code.screens.push(screen)
      } else {
        if (!this.experiment.code.sections[index].screens) this.experiment.code.sections[index].screens = []
        this.experiment.code.sections[index].screens.push(screen)
        this.build(this.tabs.names[index])
      }
      this.$forceUpdate()
    },
    addElement(secIndex, scrIndex, elIndex) {
      let el = this.elements()[elIndex]
      el.hash = uniqid.time()
      if (secIndex === 'template' && this.template.type === 'section') {
        if (!this.template.code.screens[scrIndex].elements) this.template.code.screens[scrIndex].elements = []
        this.template.code.screens[scrIndex].elements.push(el)
      } else if (secIndex === 'template' && this.template.type === 'screen') {
        if (!this.template.code.elements) this.template.code.elements = []
        this.template.code.elements.push(el)
      } else {
        if (!this.experiment.code.sections[secIndex].screens[scrIndex].elements) this.experiment.code.sections[secIndex].screens[scrIndex].elements = []
        this.experiment.code.sections[secIndex].screens[scrIndex].elements.push(el)
      }
      this.createHtml(secIndex, scrIndex)
    },
    editElement(secIndex, scrIndex, elIndex) {
      let content = null
      if (secIndex === 'template' && this.template.type === 'section') content = this.template.code.screens[scrIndex].elements[elIndex]
      else if (secIndex === 'template' && this.template.type === 'screen') content = this.template.code.elements[elIndex]
      else content = this.experiment.code.sections[secIndex].screens[scrIndex].elements[elIndex]
      this.editingElement = {
        section: secIndex,
        screen: scrIndex,
        element: elIndex,
        content: content
      }
      this.editElementModal = true
    },
    dropElement(secIndex, scrIndex, elIndex) {
      if (secIndex === 'template' && this.template.type === 'section') this.template.code.screens[scrIndex].elements.splice(elIndex, 1)
      else if (secIndex === 'template' && this.template.type === 'screen') this.template.code.elements.splice(elIndex, 1)
      else this.experiment.code.sections[secIndex].screens[scrIndex].elements.splice(elIndex, 1)
      this.previewScreen(secIndex, scrIndex)
      this.$forceUpdate()
    },
    editElementModalSave() {
      if (this.editingElement.section === 'template' && this.template.type === 'section') this.template.code.screens[this.editingElement.screen].elements[this.editingElement.element] = this.editingElement.content
      else if (this.editingElement.section === 'template' && this.template.type === 'screen') this.template.code.elements[this.editingElement.element] = this.editingElement.content
      else this.experiment.code.sections[this.editingElement.section].screens[this.editingElement.screen].elements[this.editingElement.element] = this.editingElement.content
      this.previewScreen(this.editingElement.section, this.editingElement.screen)
      this.createHtml(this.editingElement.section, this.editingElement.screen)
      this.editElementModalHide()
    },
    editElementModalHide() {
      this.editingElement = null
      this.editElementModal = false
    },
    elementTemplateDraw() {
      let options = this.template.code.template.match(/@\w*@/g)
      if (options !== null) {
        options.forEach((option, i) => {
          option = option.replace(/@/g, '')
          options[i] = option
          if (typeof this.template.code.options[option] === 'undefined' && option !== '') this.template.code.options[option] = ''
        })
      }
      let html = this.template.code.template
      for (var option in this.template.code.options) {
        html = html.replace('@' + option + '@', this.template.code.options[option])
      }
      this.template.code.html = html + '<style>' + this.template.code.style + '</style>'
      for (var option in this.template.code.options) {
        if (this.template.code.template.search('@' + option + '@') < 0) {
          delete this.template.code.options[option]
        }
      }
    },
    previewScreen (secIndex, scrIndex) {
      let html = ''
      if (this.template && this.template.type === 'section') {
        document.querySelector('#preview-screen-template-' + scrIndex).contentDocument.body.innerHTML = "";
        if(this.template.code.screens[scrIndex].elements && this.template.code.screens[scrIndex].elements.length > 0) {
          this.template.code.screens[scrIndex].elements.forEach(element => {
            let elementHtml = element.template
            for (var option in element.options) {
              elementHtml = elementHtml.replace('@' + option + '@', element.options[option])
            }
            html += elementHtml + '<style>' + element.style + '</style>'
          })
        }
        if (!this.template.code.style) this.template.code.style = ''
        if (!this.template.code.screens[scrIndex].style) this.template.code.screens[scrIndex].style = ''
        if (!this.template.code.screens[scrIndex].additionalHtml) this.template.code.screens[scrIndex].additionalHtml = ''
        html = '<!DOCTYPE html><html><head><meta charset="utf-8"/><meta http-quiv="Content-Type" content="text/html; charset=utf-8"/><meta name="viewport" content="width=device-width, height=device-height, initial-scale=1.0, minimum-scale=1.0"><style>' + this.template.code.style + this.template.code.screens[scrIndex].style + '</style></head><body><div class="screen-content"><div>' + html + this.template.code.screens[scrIndex].additionalHtml + '</div></div></body></html>'
        document.querySelector('#preview-screen-template-' + scrIndex).contentDocument.write(html)
      } else if (this.template && this.template.type === 'screen') {
        document.querySelector('#preview-screen-template-screen').contentDocument.body.innerHTML = "";
        if(this.template.code.elements && this.template.code.elements.length > 0) {
          this.template.code.elements.forEach(element => {
            let elementHtml = element.template
            for (var option in element.options) {
              elementHtml = elementHtml.replace('@' + option + '@', element.options[option])
            }
            html += elementHtml + '<style>' + element.style + '</style>'
          })
        }
        if (!this.template.code.style) this.template.code.style = ''
        if (!this.template.code.additionalHtml) this.template.code.additionalHtml = ''
        html = '<!DOCTYPE html><html><head><meta charset="utf-8"/><meta http-quiv="Content-Type" content="text/html; charset=utf-8"/><meta name="viewport" content="width=device-width, height=device-height, initial-scale=1.0, minimum-scale=1.0"><style>' + this.template.code.style + '</style></head><body><div class="screen-content"><div>' + html + this.template.code.additionalHtml + '</div></div></body></html>'
        document.querySelector('#preview-screen-template-screen').contentDocument.write(html)
      } else if (this.template && this.template.type === 'element') {
        document.querySelector('#preview-screen-template-element').contentDocument.body.innerHTML = "";
        html = '<!DOCTYPE html><html><head><meta charset="utf-8"/><meta http-quiv="Content-Type" content="text/html; charset=utf-8"/><meta name="viewport" content="width=device-width, height=device-height, initial-scale=1.0, minimum-scale=1.0"></head><body><div class="screen-content"><div>' + this.template.code.html + '</div></div></body></html>'
        document.querySelector('#preview-screen-template-element').contentDocument.write(html)
      } else {
        document.querySelector('#preview-screen-' + secIndex + '-' + scrIndex).contentDocument.body.innerHTML = "";
        if(this.experiment.code.sections[secIndex].screens[scrIndex].elements && this.experiment.code.sections[secIndex].screens[scrIndex].elements.length > 0) {
          this.experiment.code.sections[secIndex].screens[scrIndex].elements.forEach(element => {
            let elementHtml = element.template
            for (var option in element.options) {
              elementHtml = elementHtml.replace('@' + option + '@', element.options[option])
            }
            html += elementHtml + '<style>' + element.style + '</style>'
          })
        }
        if (!this.experiment.code.style) this.experiment.code.style = ''
        if (!this.experiment.code.sections[secIndex].style) this.experiment.code.sections[secIndex].style = ''
        if (!this.experiment.code.sections[secIndex].screens[scrIndex].style) this.experiment.code.sections[secIndex].screens[scrIndex].style = ''
        if (!this.experiment.code.sections[secIndex].screens[scrIndex].additionalHtml) this.experiment.code.sections[secIndex].screens[scrIndex].additionalHtml = ''
        html = '<!DOCTYPE html><html><head><meta charset="utf-8"/><meta http-quiv="Content-Type" content="text/html; charset=utf-8"/><meta name="viewport" content="width=device-width, height=device-height, initial-scale=1.0, minimum-scale=1.0"><style>' + this.experiment.code.style + this.experiment.code.sections[secIndex].style + this.experiment.code.sections[secIndex].screens[scrIndex].style + '</style></head><body><div class="screen-content"><div>' + html + this.experiment.code.sections[secIndex].screens[scrIndex].additionalHtml + '</div></div></body></html>'
        document.querySelector('#preview-screen-' + secIndex + '-' + scrIndex).contentDocument.write(html)
      }
    },
    setPreviewOrientation(o) {
      localStorage.setItem('previewOrientation', o)
      this.previewOrientation = o
    },
    saveAsTemplate(type, secIndex, scrIndex) {
      var code = null
      if (type === 'experiment') code = this.experiment.code
      else if (type === 'section') code = this.experiment.code.sections[secIndex]
      else if (type === 'screen') code = this.experiment.code.sections[secIndex].screens[scrIndex]
      this.savingTemplate = {
        admin_id: localStorage.getItem('admin_id'),
        type: type,
        name: '',
        tags: [],
        code: code
      }
      this.saveAsTemplateModal = true
    },
    saveAsTemplateModalSave() {
      var self = this
      var formData = new FormData()
      formData.append('admin_id', this.savingTemplate.admin_id)
      formData.append('type', this.savingTemplate.type)
      formData.append('name', this.savingTemplate.name)
      formData.append('tags', this.savingTemplate.tags)
      formData.append('code', JSON.stringify(this.savingTemplate.code))
      var xhr = new XMLHttpRequest()
      xhr.open('POST', this.$root.apiUrl + 'v1/templates')
      xhr.setRequestHeader('Authorization', localStorage.getItem('token'))
      xhr.onload = function () {
        if (xhr.status === 401) self.$router.push({name: 'Login'})
        var response = JSON.parse(xhr.responseText)
        if (xhr.status === 201) {
          self.notifyVue('top', 'right', 'Template saved succesfully.')
        } else {
          var message
          if (response.response && response.response[0] && response.response[0].message)
            message = response.response[0].message
          else
            message = 'Unknown error'
          self.showSweetAlertError(message)
        }
        self.saveAsTemplateModalHide()
      }
      xhr.send(formData)
    },
    saveAsTemplateModalHide() {
      this.savingTemplate = null
      this.saveAsTemplateModal = false
    },
    loadFromTemplate(type, secIndex, scrIndex) {
      if (type === 'element') {
        this.loadingTemplate = {
          type: type,
          secIndex: secIndex,
          scrIndex: scrIndex
        }
        this.loadFromTemplateModal = true
      } else {
        swal({
          title: 'Are you sure?',
          text: 'Choosing a template will overwrite the existing ' + type + '.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, overwrite!',
          cancelButtonText: 'No',
          confirmButtonClass: 'md-button md-success',
          cancelButtonClass: 'md-button md-danger',
          buttonsStyling: false
        }).then((result) => {
          if (result.value) {
            this.loadingTemplate = {
              type: type,
              secIndex: secIndex,
              scrIndex: scrIndex
            }
            this.loadFromTemplateModal = true
          }
        })
      }
    },
    loadFromTemplateModalHide() {
      this.loadingTemplate = null
      this.loadFromTemplateModal = false
    },
    manageGroupsModalHide() {
      this.manageGroupsModal = false
    },
    loadTemplate(value, name) {
      if (this.loadingTemplate.type === 'experiment') {
        this.experiment.code = JSON.parse(value)
      } else if (this.loadingTemplate.type === 'section' && typeof this.loadingTemplate.secIndex !== 'undefined') {
        this.experiment.code.sections[this.loadingTemplate.secIndex] = JSON.parse(value)
      } else if (this.loadingTemplate.type === 'screen' && typeof this.loadingTemplate.secIndex !== 'undefined' && typeof this.loadingTemplate.scrIndex !== 'undefined') {
        let scr = JSON.parse(value)
        scr.hash = uniqid.time()
        scr.name = 'SCREEN_' + scr.hash
        this.experiment.code.sections[this.loadingTemplate.secIndex].screens[this.loadingTemplate.scrIndex] = scr
      } else if (this.loadingTemplate.type === 'element' && typeof this.loadingTemplate.secIndex !== 'undefined' && typeof this.loadingTemplate.scrIndex !== 'undefined') {
        let el = JSON.parse(value)
        el.hash = uniqid.time()
        el.name = name
        if (this.template && this.template.type === 'section') {
          if (!this.template.code.screens[this.loadingTemplate.scrIndex].elements) this.template.code.screens[this.loadingTemplate.scrIndex].elements = []
          this.template.code.screens[this.loadingTemplate.scrIndex].elements.push(el)
        } if (this.template && this.template.type === 'screen') {
          if (!this.template.code.elements) this.template.code.elements = []
          this.template.code.elements.push(el)
        } else {
          if (!this.experiment.code.sections[this.loadingTemplate.secIndex].screens[this.loadingTemplate.scrIndex].elements) this.experiment.code.sections[this.loadingTemplate.secIndex].screens[this.loadingTemplate.scrIndex].elements = []
          this.experiment.code.sections[this.loadingTemplate.secIndex].screens[this.loadingTemplate.scrIndex].elements.push(el)
        }
        this.previewScreen(this.loadingTemplate.secIndex, this.loadingTemplate.scrIndex)
      }
      this.loadingTemplate = null
      this.loadFromTemplateModal = false
    },
    createHtml(secIndex, scrIndex) {
      if (secIndex === 'template' && this.template.type === 'section') {
        this.template.code.screens[scrIndex].elements.forEach((element, i) => {
          let html = element.template
          for (var option in element.options) {
            html = html.replace('@' + option + '@', element.options[option])
          }
          this.template.code.screens[scrIndex].elements[i].html = html
        })
        this.$forceUpdate()
      } else if (secIndex === 'template' && this.template.type === 'screen') {
        this.template.code.elements.forEach((element, i) => {
          let html = element.template
          for (var option in element.options) {
            html = html.replace('@' + option + '@', element.options[option])
          }
          this.template.code.elements[i].html = html
        })
        this.$forceUpdate()
      } else {
        this.experiment.code.sections[secIndex].screens[scrIndex].elements.forEach((element, i) => {
          let html = element.template
          for (var option in element.options) {
            html = html.replace('@' + option + '@', element.options[option])
          }
          this.experiment.code.sections[secIndex].screens[scrIndex].elements[i].html = html + '<style>' + this.experiment.code.sections[secIndex].screens[scrIndex].elements[i].style + '</style>'
        })
        this.build(this.tabs.names[secIndex])
      }
    },
    dropScreen(secIndex, scrIndex) {
      if (secIndex === 'template') {
        swal({
          title: 'Are you sure to delete screen:<br />"' + this.template.code.screens[scrIndex].name +'"?',
          text: '',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete!',
          cancelButtonText: 'No',
          confirmButtonClass: 'md-button md-success',
          cancelButtonClass: 'md-button md-danger',
          buttonsStyling: false
        }).then((result) => {
          if (result.value) {
            this.template.code.screens.splice(scrIndex, 1)
            this.$forceUpdate()
          }
        })
      } else {
        swal({
          title: 'Are you sure to delete screen:<br />"' + this.experiment.code.sections[secIndex].screens[scrIndex].name +'"?',
          text: '',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete!',
          cancelButtonText: 'No',
          confirmButtonClass: 'md-button md-success',
          cancelButtonClass: 'md-button md-danger',
          buttonsStyling: false
        }).then((result) => {
          if (result.value) {
            this.experiment.code.sections[secIndex].screens.splice(scrIndex, 1)
            this.build(this.tabs.names[secIndex])
          }
        })
      }
    },
    moveUpScreen(secIndex, scrIndex) {
      if (secIndex === 'template') {
        this.template.code.screens = this.arrayMove(this.template.code.screens, scrIndex, scrIndex - 1)
        this.$forceUpdate()
      } else {
        this.experiment.code.sections[secIndex].screens = this.arrayMove(this.experiment.code.sections[secIndex].screens, scrIndex, scrIndex - 1)
        this.build(this.tabs.names[secIndex])
      }
    },
    moveDownScreen(secIndex, scrIndex) {
      if (secIndex === 'template') {
        this.template.code.screens = this.arrayMove(this.template.code.screens, scrIndex, scrIndex + 1)
        this.$forceUpdate()
      } else {
        this.experiment.code.sections[secIndex].screens = this.arrayMove(this.experiment.code.sections[secIndex].screens, scrIndex, scrIndex + 1)
        this.build(this.tabs.names[secIndex])
      }
    },
    moveUpElement(secIndex, scrIndex, elIndex) {
      if (secIndex === 'template' && this.template.type === 'section') {
        this.template.code.screens[scrIndex].elements = this.arrayMove(this.template.code.screens[scrIndex].elements, elIndex, elIndex - 1)
        this.$forceUpdate()
      } else if (secIndex === 'template' && this.template.type === 'screen') {
        this.template.code.elements = this.arrayMove(this.template.code.elements, elIndex, elIndex - 1)
        this.$forceUpdate()
      } else {
        this.experiment.code.sections[secIndex].screens[scrIndex].elements = this.arrayMove(this.experiment.code.sections[secIndex].screens[scrIndex].elements, elIndex, elIndex - 1)
        this.build(this.tabs.names[secIndex])
      }
      this.previewScreen(secIndex, scrIndex)
    },
    moveDownElement(secIndex, scrIndex, elIndex) {
      if (secIndex === 'template' && this.template.type === 'section') {
        this.template.code.screens[scrIndex].elements = this.arrayMove(this.template.code.screens[scrIndex].elements, elIndex, elIndex + 1)
        this.$forceUpdate()
      } else if (secIndex === 'template' && this.template.type === 'screen') {
        this.template.code.elements = this.arrayMove(this.template.code.elements, elIndex, elIndex + 1)
        this.$forceUpdate()
      } else {
        this.experiment.code.sections[secIndex].screens[scrIndex].elements = this.arrayMove(this.experiment.code.sections[secIndex].screens[scrIndex].elements, elIndex, elIndex + 1)
        this.build(this.tabs.names[secIndex])
      }
      this.previewScreen(secIndex, scrIndex)
    },
    addGroup() {
      if(!this.experiment.code.groups) this.experiment.code.groups = []
      let sections = []
      this.experiment.code.sections.forEach((section, secIndex) => {
        sections.push({
          selected: false,
          disabled: false
        })
      })
      this.experiment.code.groups.push({
        sections: sections,
        shuffleSections: true
      })
      this.setGroupsSectionsAvailability()
      this.$forceUpdate()
    },
    changeGroup(v, secIndex, groupIndex) {
      if(v === true) {
        this.experiment.code.groups.forEach((group, i) => {
          if(i !== groupIndex) {
            // this.experiment.code.groups[i].sections[secIndex].selected = false
          }
        })
      } else if (v === false) {
        // zabezpieczenie, żeby nie dało się zrobić dziury w grupie
        if(this.experiment.code.groups[groupIndex].sections[secIndex-1] && this.experiment.code.groups[groupIndex].sections[secIndex-1].selected == true
            && this.experiment.code.groups[groupIndex].sections[secIndex+1] && this.experiment.code.groups[groupIndex].sections[secIndex+1].selected == true) {
          this.experiment.code.groups[groupIndex].sections[secIndex].selected = true
        }
      }
      this.setGroupsSectionsAvailability()
      this.$forceUpdate()
    },
    dropGroup(index) {
      this.experiment.code.groups.splice(index, 1)
      this.$forceUpdate()
    },
    setGroupsSectionsAvailability() {
      let selectedSections = {}
      this.experiment.code.groups.forEach((g, gi) => {
        // jeżeli grupa pusta to wszystkie sekcje aktywne
        let empty = true
        g.sections.forEach((s, si) => {
          if(s.selected) {
            empty = false
            this.experiment.code.groups[gi].max = si
            selectedSections[si] = true
          }
        })
        if(!empty) {
          g.sections.forEach((s, si) => {
            this.experiment.code.groups[gi].sections[si].disabled = true
          })
        } else {
          g.sections.forEach((s, si) => {
            this.experiment.code.groups[gi].sections[si].disabled = false
          })
        }
      })
      console.log(selectedSections)
      this.experiment.code.groups.forEach((g, gi) => {
        g.sections.forEach((s, si) => {
          if(s.selected) {
            // pozwala na zaznaczenie tylko sąsiednich sekcji w grupie
            this.experiment.code.groups[gi].sections[si].disabled = false
            if(this.experiment.code.groups[gi].sections[si-1] && !selectedSections[si-1]) this.experiment.code.groups[gi].sections[si-1].disabled = false
            if(this.experiment.code.groups[gi].sections[si+1] && !selectedSections[si+1]) this.experiment.code.groups[gi].sections[si+1].disabled = false
            // żeby w innych grupach nie dało się zaznaczyć wybranych sekcji
            this.experiment.code.groups.forEach((g2, g2i) => {
              if(gi !== g2i) {
                this.experiment.code.groups[g2i].sections[si].disabled = true
              }
            })
          }
        })

        // żeby w kolejnych grupach nie dało się zaznaczać wcześniejszych sekcji
        if(this.experiment.code.groups[gi-1] && this.experiment.code.groups[gi-1].max) {
          g.sections.forEach((s, si) => {
            if(si < this.experiment.code.groups[gi-1].max) {
              this.experiment.code.groups[gi].sections[si].disabled = true
            }
          })
        }
      })
      this.experiment.code.groups.reverse().forEach((g, gi) => {
        // żeby w poprzednich grupach nie dało się zaznaczać późniejszych sekcji
        if(this.experiment.code.groups[gi-1] && this.experiment.code.groups[gi-1].max) {
          g.sections.forEach((s, si) => {
            if(si > this.experiment.code.groups[gi-1].max) {
              this.experiment.code.groups[gi].sections[si].disabled = true
            }
          })
        }
      })
      this.experiment.code.groups.reverse()
    },
    validate() {
      this.$validator.validateAll().then(isValid => {
        if(isValid) {
          this.experiment.code.sections.push({
            name: this.newSectionName,
            icon: 'crop_free',
            shuffle: null,
            shuffleScreens: null,
            screens: [],
            scripts: {
              before: '',
              after: ''
            }
          })
          let name = this.newSectionName
          this.newSectionName = null
          this.touched.newSectionName = false
          this.resetGroups()
          this.build(name)
        }
      })
    },
    showSweetAlertError (message) {
      swal({
        title: 'An error occured!',
        text: message,
        type: 'warning',
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'Try again',
        cancelButtonClass: 'md-button md-success',
        buttonsStyling: false
      })
    },
    notifyVue (verticalAlign, horizontalAlign, text) {
      this.$notify(
        {
          message: text,
          icon: 'save',
          horizontalAlign: horizontalAlign,
          verticalAlign: verticalAlign,
          type: 'success'
        }
      )
    },
    arrayMove(arr, old_index, new_index) {
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr;
    }
  },
  watch: {
    newSectionName() {
      this.touched.newSectionName = true
    },
    experiment() {
      this.touched.experiment = true
    },
    type(value) {
      this.type = value
    },
    templateType(value) {
      this.templateType = value
    },
    initialData(value) {
      if (this.type === 'experiment') {
        this.experiment = value
        this.experiment.codeString = beautify_js(this.experiment.code, { indent_size: 2 })
        this.experiment.code = JSON.parse(this.experiment.code)
        this.status = this.experiment.status
        this.build()
      } else if (this.type === 'template' && this.templateType === 'experiment') {
        this.experiment = value
        this.build()
      } else if (this.type === 'template' && this.templateType !== 'experiment') {
        this.template = value
        if (this.templateType === 'element') {
          this.elementTemplateDraw()
        }
      }
    },
    status(value, prev) {
      this.experiment.status = value
      if(prev) this.save()
    }
  }
}
</script>
<style lang="scss" scoped>
.experiment-editor {
  margin-top: -25px!important;
}
.experiment-editor.md-card,
.experiment-editor.md-card-tabs {
  margin: 0;
}
.save-button-top {
  float: right;
}
.play-button {
  float: right;
  margin-bottom: 15px !important;
}
.get-report-button {
  float: right;
  margin-right: 15px !important;
  margin-bottom: 15px !important;
}
.toggle-mode-button {
  float: right;
  margin-right: 10px !important;
}
.manage-parts-button {
  margin: -10px 0 0 39px;
  width: 113px;
  z-index: 10;
}
.save-button-bottom {
  text-align: right;
}
.code-mode-container {
  position: relative;
  margin: -70px 10px 0;
  height: 100%;
}
.panel-icon-bar {
  position: absolute;
  top: 0;
  right: 0;
  .md-button.md-simple i:hover {
    background-color: #eee;
  }
}
.experiment-main-properties {
  .panel-icon-bar {
    right: 30px;
  }
}
iframe {
  width: 100%;
  height: 100%;
}
.screen-preview-buttons {
  text-align: center;
}
.screen-container {
  padding-left: 20px;
  position: relative;
}
.screen-container::before {
  content: "";
  display: block;
  width: 4px;
  height: 50px;
  background: #999;
  position: absolute;
  left: 65px;
  top: -35px;
}
.screen-content {
  background: #fff;
	position: relative;
  border: 3px #ddd solid;
  border-radius: 10px;
  z-index: 9999;
  &.landscape {
    width: 100%;
    padding-bottom: 75%; /* 4:3 */
  }
  &.portrait {
    height: 400px;
    width: 225px;
    margin: 0 auto;
  }
}
.screen-content > div {
  position: absolute;
	top: 0; bottom: 0; left: 0; right: 0;
  overflow: hidden;
}
.template-screen-preview {
  margin: 30px 0 20px;
}
.template-element-options {
  margin: 30px 0 20px;
}
.copy-button {
  position: relative;
  top: -12px;
}
</style>
<style lang="scss">
.md-collapse-content {
  height: 300px;
  margin-bottom: 70px;
}
.manage-groups-modal {
  .groups-items {
    position: relative;
    max-height: 450px;
    overflow-y: scroll;
  }
}
.edit-element-modal {
  padding-right: 15px;
  .edit-element-options {
    position: relative;
    max-height: 350px;
    overflow-y: scroll;
  }
}
.CodeMirror {
  position:absolute;
  top: 70px;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 9999;
}
.template-element-css-container,
.template-element-html-container {
  position: relative;
  height: 350px;
  margin-bottom: 80px;
  .CodeMirror {
    left: 10px;
    right: 10px;
  }
}
.screen-content {
  iframe {
    border: 0;
    border-radius: 6px;
    width: 100% !important;
    height: 100% !important;
  }
  .el {
    position: relative;
    cursor: pointer !important;
    z-index: 99999;
  }
}
.group-item {
  border-bottom: 1px #ddd solid;
  h6 {
    margin-bottom: 0;
  }
  .md-checkbox {
    .md-checkbox-label {
      font-size: 12px;
      color: #222;
      text-transform: uppercase;
      line-height: 22px;
    }
  }
  .drop-group-button {
    margin-top: 20px;
  }
}
.shuffle-group-switch {
  margin-top: 11px;
}
.manage-groups-modal {
  .modal-wrapper {
    .modal-container {
      width: 60%;
      max-width: 60% !important;
    }
  }
}
</style>
